
import React from "react";

import Discord from "../../../../data/images/waterproofjack/svgs/discord.svg";

import "./discord-button.scss";

export const DiscordButton = (props) => {
    const containerClass = props.className ? `${props.className} c-discord-button` : "c-discord-button";

    return (
        <div className={containerClass}>
            <a className={`c-discord-button__discord-button g-flex__container g-flex__container--no-wrap g-flex__container--align-center g-flex__container--justify-center ${props.buttonClass}`} href="https://discord.gg/8x8BBrzd4C">
                <Discord className="g-flex__item c-discord-button__discord-icon" />
                <span className="g-flex__item">Join our Discord</span>
            </a>
        </div>
    );
};