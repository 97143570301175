import React from "react";

import { useStaticQuery, graphql } from "gatsby";

import { Image } from "../image";

import "./polaroids.scss";

export const Polaroids = (props) => {
    const { imageOne, imageTwo, imageThree } = useStaticQuery(
        graphql`
            query PolaroidsQuery {
                imageOne: file(sourceInstanceName: {eq: "aboutUsImages"}, name: {eq: "me"}) {
                    childImageSharp {
                        gatsbyImageData(
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                        )
                    }
                },
                imageTwo: file(sourceInstanceName: {eq: "aboutUsImages"}, name: {eq: "me2"}) {
                    childImageSharp {
                        gatsbyImageData(
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                        )
                    }
                }
            }
    `);

    return(
        <div className={`c-polaroids__container ${props.containerClassName}`}>
            <div className={"c-polaroids__image-outer"}>
                <div className="c-polaroids__polaroid-outer--1">
                    <Image
                        className="c-polaroids__image"
                        imgClassName=""
                        //src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                        image={imageTwo}
                        alt=""
                        objectFit="cover"
                        objectPosition="50% 50%"
                        gradientOverlay={"c-product-walkthrough__background-image-gradient"}
                    />
                </div>
                <div className="c-polaroids__polaroid-outer--2">
                    <Image
                        className="c-polaroids__image"
                        imgClassName=""
                        //src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                        image={imageOne}
                        alt=""
                        objectFit="cover"
                        objectPosition="50% 50%"
                        gradientOverlay={"c-product-walkthrough__background-image-gradient"}
                    />
                </div>
                {/* <div className="c-polaroids__polaroid-outer--3">
                    <Image
                        className="c-polaroids__image"
                        imgClassName=""
                        //src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                        image={imageThree}
                        alt=""
                        objectFit="cover"
                        objectPosition="50% 50%"
                        gradientOverlay={"c-product-walkthrough__background-image-gradient"}
                    />
                </div> */}
            </div>
        </div>
    );
};
