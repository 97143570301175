import { useStaticQuery, graphql } from "gatsby";

const getSeason = (region, month) => {
    if (region === "us" || region === "gb" || region === "ca") {
        if (month === "may" || month === "june" || month === "july" || month === "august" || month === "september") {
            return "summer";
        }

        if (month === "october" || month === "november" || month === "december" || month === "january" || month === "february" || month === "march" || month === "april") {
            return "winter";
        }
    }
    if (region === "au" || region === "nz") {
        if (month === "may" || month === "june" || month === "july" || month === "august" || month === "september" || month === "october") {
            return "winter";
        }

        if (month === "november" || month === "december" || month === "january" || month === "february" || month === "march" || month === "april") {
            return "summer";
        }
    }

    return null;
};

export const useBackgroundImage = (region) => {
    if (region) {
        let backgroundImage;
        const date = new Date();
        const monthNames = ["january","february","march","april","may","june","july","august","september","october","november","december"];
        let month = monthNames[date.getMonth()];
        const season = getSeason(region, month);


        const { summerBackgroundImage, winterBackgroundImage } = useStaticQuery(
            graphql`
                query {
                    summerBackgroundImage: file(sourceInstanceName: {eq: "backgroundImages"}, name: {eq: "summer-background"}) {
                        childImageSharp {
                            gatsbyImageData(
                                layout: FULL_WIDTH
                                placeholder: BLURRED
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                    winterBackgroundImage: file(sourceInstanceName: {eq: "backgroundImages"}, name: {eq: "winter-background"}) {
                        childImageSharp {
                            gatsbyImageData(
                                layout: FULL_WIDTH
                                placeholder: BLURRED
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }
              `
        );

        if (season === "winter") {
            backgroundImage = winterBackgroundImage;
        } else {
            backgroundImage = summerBackgroundImage;
        }

        if (backgroundImage) {
            return backgroundImage;
        }
    }
};
