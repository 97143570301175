import React from "react";


import "./discord.scss";

export const DiscordExplainer = (props) => {
    const containerClass = props.className ? `${props.className} c-discord-explainer u-margin-top--2` : "c-discord-explainer u-margin-top--2";

    return (
        <div className={containerClass}>
            <p className="c-discord-explainer__text">
                Become a Founding Member on our community discord to get early access to new features. We would love your feedback.
            </p>
        </div>
    );
};