import React, { useContext } from "react";

import { graphql } from "gatsby";

// import { Banner } from "../../components/banner";
//import { ProductsStacked } from '../../containers/products-stacked';
//import { Articles } from '../../components/articles';
import { FounderMessage } from "../../components/founder-message";
import { HomepageSectionBanner } from "../../components/homepage-section-banner";
import { NewFeatures } from "../../components/new-features";
import { ProductWalkthrough } from "../../components/product-walkthrough";
import { LanguageRegionContext } from "../../context-providers/language-region-context";
import {getCategories, getSubCategories } from "../../helpers/get-categories";
import { useHomepageSectionBannerImages } from "../../hooks/use-homepage-section-banner-images";
// import { useTwoArticles } from "../../hooks/use-two-articles";
import { DefaultLayout } from "../../layouts/default-layout";
// import backgroundImage from "../../images/svg/background.jpeg"

import "./home.scss";

const Home = ({ pageContext, data }) => {
    const { languageRegion } = useContext(LanguageRegionContext);
    //const articles = useTwoArticles();
    // const homepageSectionBannerImages = useHomepageSectionBannerImages();
    // const categories = getCategories(data.categories);
    // const subCategories = getSubCategories(data.subCategories);
    // const releaseStage = pageContext.siteMetadata && pageContext.siteMetadata.releaseStage;

    const pageDetails = {
        currentLanguageRegion: pageContext.currentLanguageRegion,
        title: pageContext.title,
        meta: pageContext.meta,
        script: pageContext.script,
        urlPath: pageContext.urlPath
    };

    // const HomepageSectionBanners = subCategories.map((subCategoryNode, index) => {
    //     const validCategories = categories.filter((categoryNode) => {
    //         return subCategoryNode.parentCategoryIds.some((parentCategoryId) => parentCategoryId === categoryNode.categoryId);
    //     });

    //     return <HomepageSectionBanner
    //         key={`homepage-section-${index}`}
    //         imageFile1={homepageSectionBannerImages[0][index] && homepageSectionBannerImages[0][index].node}
    //         imageFile2={homepageSectionBannerImages[1][index] && homepageSectionBannerImages[1][index].node}
    //         categories={validCategories}
    //         subCategory={subCategoryNode}
    //         languageRegion={languageRegion}
    //         paragraphText="Find the perfect jacket for you using our easy to understand waterproof, breathability and warmth ratings for all jackets. Plus, in-depth product feature scanning and web-wide customer sentiment analysis by our own artificial inteligence reviewer."
    //     />;
    // });

    return (
        <DefaultLayout className={"p-home"} pageDetails={pageDetails} background={true} darkBackground={false}>
            {/* <Banner /> */}
            {/* <h1 className="u-visually-hidden">Mens and Womens Waterproof Jackets</h1> */}

            <ProductWalkthrough languageRegion={languageRegion} />

            <NewFeatures languageRegion={languageRegion} />
            <FounderMessage sectionClassName="c-founder-message__section-background" />
            {/*
            { releaseStage > 2 &&

                <div className="g-grid__container--home-layout-banner">
                    <div className="g-grid__item--home-pl">
                    </div>
                    <div className="g-grid__item--home-main">
                        {
                            HomepageSectionBanners &&
                HomepageSectionBanners.length > 0 &&
                HomepageSectionBanners[0]
                        }
                        {
                            HomepageSectionBanners &&
                HomepageSectionBanners.length > 1 &&
                HomepageSectionBanners[1]
                        }
                        {
                            HomepageSectionBanners &&
                HomepageSectionBanners.length > 2 &&
                HomepageSectionBanners[2]
                        }
                    </div>
                    <div className="g-grid__item--home-pr"></div>
                </div>

            } */}

            {/* <div className="g-grid__container--home-layout-banner u-margin-top--1-large">
        <div className="g-grid__item--home-pl">
        </div>
        <div className="g-grid__item--home-main">
          {
            HomepageSectionBanners &&
            HomepageSectionBanners.length > 1 &&
            HomepageSectionBanners[1]
          }
        </div>
        <div className="g-grid__item--home-pr"></div>
      </div> */}

            {/* <div className="g-grid__container--home-layout u-margin-top--2">
        <div className="g-grid__item--home-pl-title">
        </div>
        <div className="g-grid__item--home-main-title">
          <h2 className="c-content__category-title">{`Today's biggest savings`}</h2>
        </div>
        <div className="g-grid__item--home-pr-title"></div>
        <div className="g-grid__item--home-pl">
        </div>
        <div className="g-grid__item--home-main">
          <ProductsStacked products={pageContext.products} productVariants={pageContext.productVariants} languageRegion={languageRegion} stackNumber={3} />
        </div>
        <div className="g-grid__item--home-pr"></div>
      </div> */}

            {/* <div className="g-grid__container--home-layout u-margin-top--2">
        <div className="g-grid__item--home-pl-title">
        </div>
        <div className="g-grid__item--home-main-title">
          <h2 className="c-content__category-title">{`Latest Articles`}</h2>
        </div>
        <div className="g-grid__item--home-pr-title"></div>
        <div className="g-grid__item--home-pl">
        </div>
        <div className="g-grid__item--home-main">
          <Articles articles={articles} />
        </div>
        <div className="g-grid__item--home-pr"></div>
      </div> */}

        </DefaultLayout >
    );
};


export const query = graphql`
  query HomeTemplate {
    categories: allCategory {
        edges {
            node {
                ...Categorys
            }
        }
    }
    subCategories: allSubCategory {
        edges {
            node {
                ...SubCategorys
            }
        }
    }
}`;

export default Home;
