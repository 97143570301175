import React from "react";

import { useStaticQuery, graphql, Link } from "gatsby";

import {useBackgroundImage} from "../../hooks/use-background-image";
import { Badges } from "../badges";
import { CTAButton } from "../buttons/call-to-action";
import { DiscordButton } from "../buttons/discord";
import { DiscordExplainer } from "../explainers/discord";
import { Image } from "../image";

import "./product-walkthrough.scss";

export const ProductWalkthrough = ({languageRegion}) => {
    const region = languageRegion.slice(-2);
    const backgroundImage = useBackgroundImage(region);
    const { jacketImage } = useStaticQuery(
        graphql`
            query {
                jacketImage: file(
                    sourceInstanceName: {eq: "productImages"},
                    name: {eq: "test-product"}
                ) {
                    childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP])
                    }
                }
            }
          `
    );

    return (
        <div className="c-product-walkthrough">

            {/* BACKGROUND START */}
            <div className="c-product-walkthrough__background">
                <Image
                    className="c-product-walkthrough__background-image-container"
                    imgWrapperClassName="c-product-walkthrough__background-image-wrapper"
                    imgClassName="c-product-walkthrough__background-image"
                    //src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                    image={backgroundImage}
                    alt=""
                    objectFit="cover"
                    objectPosition="50% 50%"
                    gradientOverlay={"c-product-walkthrough__background-image-gradient"}
                />
            </div>
            {/* BACKGROUND END */}


            <div className="g-grid__container--product-walkthrough-layout">
                <div className="g-grid__item--product-walkthrough-pl"></div>
                <div className="g-grid__item--product-walkthrough-main">
                    <div className="c-product-walkthrough__content">
                        <div className="c-product-walkthrough__search-container g-flex__container">
                            <div className="g-flex__item g-flex__container g-flex__container--column-always c-product-walkthrough__question-area">
                                <h2 className="g-flex__item--grow c-product-walkthrough__title">Find your next <span>waterproof</span> jacket.</h2>
                                <p className="g-flex__item--grow c-product-walkthrough__text">Answer a few questions to find the perfect jacket that suits your needs.</p>
                                <DiscordExplainer className="g-flex__item c-product-walkthrough__text c-product-walkthrough__text--smaller" />

                                <div className="g-flex__item--grow g-flex__container g-flex__container--align-stretch c-product-walkthrough__button-container">

                                    <CTAButton languageRegion={languageRegion} />

                                    <DiscordButton languageRegion={languageRegion} />
                                </div>
                            </div>
                            <div className="g-flex__item--grow g-flex__container g-flex__container--justify-center u-position-relative c-product-walkthrough__image-container">

                                <div className="c-product-walkthrough__rotate-box">
                                    <div className="c-product-walkthrough__rotate-item">
                                        <Badges type={"snow"} />
                                    </div>

                                    <div className="c-product-walkthrough__rotate-item">
                                        <Badges type={"under-layer-required"} />
                                    </div>

                                    <div className="c-product-walkthrough__rotate-item">
                                        <Badges type={"heavy-shower"} />
                                    </div>

                                    <div className="c-product-walkthrough__rotate-item">
                                        <Badges type={"hiking"} />
                                    </div>

                                    <div className="c-product-walkthrough__rotate-item">
                                        <Badges type={"dog-walk"} />
                                    </div>
                                </div>

                                <Image
                                    className="g-flex__item"
                                    imgClassName=""
                                    image={jacketImage}
                                    alt=""
                                    objectFit="cover"
                                    objectPosition="50% 50%"
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <div className="g-grid__item--product-walkthrough-pr"></div>
            </div>
        </div>
    );
};
