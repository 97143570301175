import React from "react";

import { faSnowflake, faCloudShowersHeavy, faDog, faPersonHiking, faCloudSunRain, faPersonBiking, faPersonSnowboarding, faPersonSkiing, faSailboat, faCloudSun } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./badges.scss";

export const Badges = (props) => {

    if (props.type === "dry") {
        return (
            <div className="g-flex__container g-flex__container--justify-center badge light-blue">
                <div className="g-flex__item circle">
                    <FontAwesomeIcon icon={faCloudSun} />
                </div>
                <div className="g-flex__item ribbon">Dry</div>
            </div>
        );
    }

    if (props.type === "light-shower") {
        return (
            <div className="g-flex__container g-flex__container--justify-center badge blue">
                <div className="g-flex__item circle">
                    <FontAwesomeIcon icon={faCloudSunRain} />
                </div>
                <div className="g-flex__item ribbon">Light Shower</div>
            </div>
        );
    }

    if (props.type === "heavy-shower") {
        return (
            <div className="g-flex__container g-flex__container--justify-center badge blue-dark">
                <div className="g-flex__item circle">
                    <FontAwesomeIcon icon={faCloudShowersHeavy} />
                </div>
                <div className="g-flex__item ribbon">Heavy Shower</div>
            </div>
        );
    }

    if (props.type === "dog-walk") {
        return (
            <div className="g-flex__container g-flex__container--justify-center badge green">
                <div className="g-flex__item circle">
                    <FontAwesomeIcon icon={faDog} />
                </div>
                <div className="g-flex__item ribbon">Dog Walk</div>
            </div>
        );
    }

    if (props.type === "biking") {
        return (
            <div className="g-flex__container g-flex__container--justify-center badge teal">
                <div className="g-flex__item circle">
                    <FontAwesomeIcon icon={faPersonBiking} />
                </div>
                <div className="g-flex__item ribbon">Biking</div>
            </div>
        );
    }

    if (props.type === "hiking") {
        return (
            <div className="g-flex__container g-flex__container--justify-center badge green-dark">
                <div className="g-flex__item circle">
                    <FontAwesomeIcon icon={faPersonHiking} />
                </div>
                <div className="g-flex__item ribbon">Hiking</div>
            </div>
        );
    }

    if (props.type === "sailing") {
        return (
            <div className="g-flex__container g-flex__container--justify-center badge sailing-blue">
                <div className="g-flex__item circle">
                    <FontAwesomeIcon icon={faSailboat} />
                </div>
                <div className="g-flex__item ribbon">Sailing</div>
            </div>
        );
    }

    if (props.type === "skiing") {
        return (
            <div className="g-flex__container g-flex__container--justify-center badge silver">
                <div className="g-flex__item circle">
                    <FontAwesomeIcon icon={faPersonSkiing} />
                </div>
                <div className="g-flex__item ribbon">Skiing</div>
            </div>
        );
    }

    if (props.type === "snowboarding") {
        return (
            <div className="g-flex__container g-flex__container--justify-center badge silver">
                <div className="g-flex__item circle">
                    <FontAwesomeIcon icon={faPersonSnowboarding} />
                </div>
                <div className="g-flex__item ribbon">Snowboarding</div>
            </div>
        );
    }

    if (props.type === "snow") {
        return (
            <div className="g-flex__container g-flex__container--justify-center badge silver">
                <div className="g-flex__item circle">
                    <FontAwesomeIcon icon={faSnowflake} />
                </div>
                <div className="g-flex__item ribbon">Snow</div>
            </div>
        );
    }

    if (props.type === "under-layer-required") {
        return (
            <div className="g-flex__container g-flex__container--justify-center badge silver">
                <div className="g-flex__item circle">
                    <FontAwesomeIcon icon={faSnowflake} />
                </div>
                <div className="g-flex__item ribbon">Under-layer Required</div>
            </div>
        );
    }

    return null;
};