
import React from "react";

import { Link } from "gatsby";

import "./call-to-action.scss";

export const CTAButton = (props) => {
    const region = props.languageRegion.slice(-2);

    return (
        <div className="c-call-to-action-button">
            <Link className="c-call-to-action-button__call-to-action-button g-flex__container g-flex__container--no-wrap g-flex__container--align-center g-flex__container--justify-center" to="/blog">
                <span className="g-flex__item">Find a jacket</span>
            </Link>
        </div>
    );
};