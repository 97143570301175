// Libs
import React from "react";

import { useStaticQuery, graphql } from "gatsby";

import { DiscordButton } from "../buttons/discord";
import { DiscordExplainer } from "../explainers/discord";
import { Image } from "../image";
//import { Link } from 'gatsby';

// CSS
import "./new-features.scss";

export const NewFeatures = (props) => {
    const { priceGuideImage, priceHistoryImage, productRatingImage, productReviewImage, badgesImage } = useStaticQuery(
        graphql`
            query {
                priceGuideImage: file(
                    sourceInstanceName: {eq: "newFeatureImages"},
                    name: {eq: "feature-1"}
                ) {
                    childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                    }
                }
                priceHistoryImage: file(
                    sourceInstanceName: {eq: "newFeatureImages"},
                    name: {eq: "feature-2"}
                ) {
                    childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                    }
                }
                productRatingImage: file(
                    sourceInstanceName: {eq: "newFeatureImages"},
                    name: {eq: "feature-3"}
                ) {
                    childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                    }
                }
                productReviewImage: file(
                    sourceInstanceName: {eq: "newFeatureImages"},
                    name: {eq: "feature-4"}
                ) {
                    childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                    }
                }
                badgesImage: file(
                    sourceInstanceName: {eq: "newFeatureImages"},
                    name: {eq: "feature-5"}
                ) {
                    childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                    }
                }
            }
          `
    );

    return (
        <div className="c-new-features">
            <div className="g-grid__container g-grid__container--new-features-layout">
                <div className="g-grid__item--new-features-pl"></div>
                <div className="g-grid__item--new-features-main">
                    <section id="new-features">
                        <div className="g-flex__container c-new-features__top-margin">
                            <div className="g-flex__item g-flex__container g-flex__container--column-always c-new-features__text-area">
                                <h2 className="g-flex__item">Top Features</h2>
                                <p className="g-flex__item c-new-features__large-text u-margin-top--1">A free, no-nonsense, jacket shopping service.</p>
                                <h3 className="g-flex__item u-margin-top--2">Free forever</h3>
                                <p className="g-flex__item u-margin-top--1">
                                Waterproof Jack will forever be free of charge for you to use.
                                We want to assist people in making a better decision without having to pay
                                for the privilege.
                                </p>
                                <h3 className="g-flex__item u-margin-top--2">Cut the marketing jargon</h3>
                                <p className="g-flex__item u-margin-top--1">
                                We hate marketing jargon! It needlessly confuses people and
                                we think that is out of order. Lets call a spade a spade
                                without beating about the bush. You shouldnt need a science degree to
                                know if your jacket will keep you dry in a downpour or warm on a winters morning.
                                </p>
                                <h3 className="g-flex__item u-margin-top--2">Find the best deals</h3>
                                <p className="g-flex__item u-margin-top--1">
                            You should buy a jacket where it is cheapest and they offers the best service. In store, online... it doesnt matter.
                            Our staff scour the globe every day for the lowest prices. We also have direct relationships with the primary suppliers. No middlemen involved.
                            Even after our best efforts, sometimes you may find a better price than we can. This is especially true if you have a loyalty or rewards card.

                            Our advice will be free regardless of whether you buy from us.
                                </p>
                                <div className="g-flex__container">
                                    <DiscordExplainer className="g-flex__item" />
                                    <DiscordButton languageRegion={props.languageRegion} />
                                </div>

                            </div>
                            <div className="c-new-features__image-area">
                                <div className="g-grid__container g-grid__container--new-features-image-layout">
                                    <div className="g-grid__item g-grid__item--new-features-image-1 c-new-features__image-1">
                                        <div className="c-new-features__wrapper g-flex__container g-flex__container--column-always g-flex__container--align-i-end g-flex__container--justify-stretch">
                                            <div className="g-flex__item c-new-features__image">
                                                <Image
                                                    className=""
                                                    imgClassName=""
                                                    //src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                    image={badgesImage}
                                                    alt=""
                                                    objectFit="cover"
                                                    objectPosition="50% 50%"
                                                />
                                            </div>
                                            <div className="g-flex__item c-new-features__text g-flex__container g-flex__container--align-center g-flex__container--justify-center">
                                                <span className="g-flex__item">Full service consumer advisor</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="g-grid__item g-grid__item--new-features-image-2 c-new-features__image-2">
                                        <div className="c-new-features__wrapper g-flex__container g-flex__container--column-always g-flex__container--align-i-end g-flex__container--justify-stretch">
                                            <div className="g-flex__item c-new-features__image">
                                                <Image
                                                    className=""
                                                    imgClassName=""
                                                    //src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                    image={priceGuideImage}
                                                    alt=""
                                                    objectFit="contain"
                                                    objectPosition="50% 50%"
                                                />
                                            </div>
                                            <div className="g-flex__item c-new-features__text g-flex__container g-flex__container--align-center g-flex__container--justify-center">
                                                <span className="g-flex__item">Price guide</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="g-grid__item g-grid__item--new-features-image-3 c-new-features__image-3">
                                        <div className="c-new-features__wrapper g-flex__container g-flex__container--column-always g-flex__container--align-i-end g-flex__container--justify-stretch">
                                            <div className="g-flex__item c-new-features__image">
                                                <Image
                                                    className=""
                                                    imgClassName=""
                                                    //src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                    image={priceHistoryImage}
                                                    alt=""
                                                    objectFit="contain"
                                                    objectPosition="50% 50%"
                                                />
                                            </div>
                                            <div className="g-flex__item c-new-features__text g-flex__container g-flex__container--align-center g-flex__container--justify-center">
                                                <span className="g-flex__item">Price history</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="g-grid__item g-grid__item--new-features-image-4 c-new-features__image-4">
                                        <div className="c-new-features__wrapper g-flex__container g-flex__container--column-always g-flex__container--align-i-end g-flex__container--justify-stretch">
                                            <div className="g-flex__item c-new-features__image">
                                                <Image
                                                    className=""
                                                    imgClassName=""
                                                    //src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                    image={productRatingImage}
                                                    alt=""
                                                    objectFit="contain"
                                                    objectPosition="50% 50%"
                                                />
                                            </div>
                                            <div className="g-flex__item c-new-features__text g-flex__container g-flex__container--align-center g-flex__container--justify-center">
                                                <span className="g-flex__item">Product ratings</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="g-grid__item g-grid__item--new-features-image-5 c-new-features__image-5">
                                        <div className="c-new-features__wrapper g-flex__container g-flex__container--column-always g-flex__container--align-i-end g-flex__container--justify-stretch">
                                            <div className="g-flex__item c-new-features__image">
                                                <Image
                                                    className=""
                                                    imgClassName=""
                                                    //src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                    image={productReviewImage}
                                                    alt=""
                                                    objectFit="contain"
                                                    objectPosition="50% 50%"
                                                />
                                            </div>
                                            <div className="g-flex__item c-new-features__text g-flex__container g-flex__container--align-center g-flex__container--justify-center">
                                                <span className="g-flex__item">Product reviews</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="g-grid__item--new-features-pr"></div>
            </div>
        </div>
    );
};