// Libs
import React from "react";

import { useStaticQuery, graphql } from "gatsby";

import { Polaroids } from "../../components/polaroids";
import { Image } from "../image";

//import { Link } from 'gatsby';

// CSS
import "./founder-message.scss";

export const FounderMessage = (props) => {
    const { signatureBlack, signatureWhite } = useStaticQuery(
        graphql`
            query {
                signatureBlack: file(sourceInstanceName: {eq: "aboutUsImages"}, name: {eq: "signature-black"}) {
                    childImageSharp {
                        gatsbyImageData(
                            layout: CONSTRAINED
                            placeholder: NONE
                            formats: [AUTO, WEBP]
                        )
                    }
                }
                signatureWhite: file(sourceInstanceName: {eq: "aboutUsImages"}, name: {eq: "signature-white"}) {
                    childImageSharp {
                        gatsbyImageData(
                            layout: CONSTRAINED
                            placeholder: NONE
                            formats: [AUTO, WEBP]
                        )
                    }
                }
            }
          `
    );

    return (
        <div className="c-founder-message">
            <div className="g-grid__container g-grid__container--founder-message-layout">
                <div className="g-grid__item--content-pl"></div>
                <div className="g-grid__item--content-main">
                    <section id="founder-message" className={props.sectionClassName}>
                        <div className="g-grid__container g-grid__container--home-founder-message">
                            <Polaroids containerClassName="g-grid__item g-grid__item--one" />
                            <div className="g-grid__item g-grid__item--two">

                                <div className="c-founder-message__text-wrapper">
                                    <h2>
                                        Message from the Founder
                                    </h2>
                                    <p className="c-founder-message__text u-margin-top--2">
                                Hello everyone, my name is Liam Raven, founder of Waterproof Jack. Let me tell you about why I started.<br />
                                    </p>
                                    <p className="c-founder-message__text">
                                I grew up in the Lake District, United Kingdom. It rains a lot; those lakes dont fill themselves. Back in the 90s it was common to have a few feet of snow during winter. Some of my first memories are of playing with my brother in the snow (see pictures).<br />
                                    </p>
                                    <p className="c-founder-message__text">
                                My grandparents started an outdoor clothing and equipment retailer in 1969, when they retired, my dad and uncle continued to run the business and still do today.
                                In my teenage years, I would spend hours at the store explaining the intricacies of rain jackets and snow jackets to customers.
                                    </p>
                                    <p className="c-founder-message__text">
                                Fast forward over a decade. <br />
                                    </p>
                                    <p className="c-founder-message__text">
                                With great advances in consumer experiences globally you would have thought that the simple task of researching and choosing a suitable waterproof jacket would have been solved by now.
                                Let me tell you that this is not the case. Choice is abundant, but real insight is not. <br />
                                    </p>
                                    <p className="c-founder-message__text">
                                After 4 years of painstaking research and development, I have created the worlds only full service consumer advisor on waterproof jackets.
                                Me and my team have simplified all the components of what we feel matters in a waterproof jacket. <br />
                                Then, using artificial intelligence and machine-learning, we have loaded up the cheapest available retail prices on each jacket model, colour and size to ensure you always get the best buy price.
                                    </p>
                                    <p className="c-founder-message__text">
                                We are almost ready to launch our alpha release, join our community discord to get exclusive access. We would love to get your feedback and ideas.
                                    </p>
                                    <p className="c-founder-message__text">
                                Yours sincerely,
                                    </p>
                                    <Image
                                        className="c-founder-message__signature"
                                        imgClassName=""
                                        //src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                        image={props.darkBackground ? signatureWhite : signatureBlack}
                                        alt=""
                                        objectFit="cover"
                                        objectPosition="50% 50%"
                                        gradientOverlay={"c-product-walkthrough__background-image-gradient"}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="g-grid__item--content-pr"></div>
            </div>
        </div>
    );
};